import React from "react";
import {CImg, CContainer, CRow, CCol} from '@coreui/react'
import { imgs } from '../../../../assets/image/index';
import {indexGeneral} from '../../../../language/vn/general/_indexGeneral';
import styles from './splash.module.css';
function Splash() {
  return (
    <div className={`c-app c-default-layout flex-row align-items-center ${styles.backgroundScreen}`}>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="4" className={`justify-content-center d-flex ${styles.box} ${styles.bounce}  text-center`}> 
            <CImg  src={imgs.LOGO} /><br/>
            <h5 className="text-white">{indexGeneral.COMPANY}</h5>
            <h6 className="text-white">{indexGeneral.COMAPNT_EN}</h6><br/><br/>
          </CCol>
        </CRow>
      </CContainer>

    </div>
  );
}

export default Splash;