import alertLang from './alert';
import docsExplorer from './docs_explorer';
import emailSetting from './email_setting';
import systemCategory from './system_category';
import splashLang from './splash';
import roleSetting from './role_setting';

export const indexGeneral = Object.assign({},
    alertLang,
    docsExplorer,
    emailSetting,
    systemCategory,
    splashLang,
    roleSetting
)