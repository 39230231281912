import { gql } from "@apollo/client";
export const ADD_EMERGENCY = gql`
    mutation(
        $input: AddWorkInput!,
        $files: [Upload],
    ){
        addEmergencyWork(
            input: $input,
            files : $files,
        ){
            _id
            status
        }
    }
`;
export const UPDATE_EMERGENCY = gql`
    mutation($_id: String!, $input: AddWorkInput!,$files: [Upload]){
        updateEmergencyWork(
            _id: $_id,
            input: $input,
            files : $files,){
            _id
            }
    }
`