import { createStore } from 'redux'
const initialState = {
  sidebarShow: 'responsive',
  routers: [],
  navigation: [],
  routerLink: [],
  userData: {
    id: 0
  },
  prevLink: '',
  paramLink: '',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'route':
      return { ...state, ...rest }
    case 'navigation':
      return { ...state, ...rest }
    case 'routerLink':
      return { ...state, ...rest }
    case 'user':
      return { ...state, ...rest }
    case 'prevLink': {
      return { ...state, ...rest }
    }
    case 'paramLink': {
      return { ...state, ...rest }
    }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store