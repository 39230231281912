import logo from './logo.png';
import defaultMaleAvatar from './user.png';
import loading from './Loading.gif';
import success from './success.jpg';
import wrong from './wrong.jpg';
import add from './add.png';
import water from './water.jpg';
import backGround from './background.jpg';
import imageGround from './image.jpg';

export const imgs = Object.assign({}, {
    LOGO: logo,
    DEFAULT_MALE_AVATAR: defaultMaleAvatar,
    LOADING: loading,
    SUCCESS: success,
    WRONG: wrong,
    ADD: add,
    WATER: water,
    BACK_GROUND: backGround,
    IMAGE_GROUND: imageGround
});