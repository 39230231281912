import { gql } from '@apollo/client';
export const GET_EMERGENCY_WORK = gql`
  query getPaginateEmergencyWork(
    $search: String
    $status: [EnumAllStatusWork]!
    $type: Int
    $page: Int
    $factoriesId: [String]
    $stationsId: [String]
    $limit: Int
    $isAccept: Int
    $startDate: DateTime
    $endDate: DateTime
    $workCodesId: [String]
  ) {
    getPaginateEmergencyWork(
      searchWork: {
        searchOptions: {
          search: $search
          status: $status
          type: $type
          isAccept: $isAccept
          startDate: $startDate
          endDate: $endDate
          stationsId: $stationsId
          workCodesId: $workCodesId
          factoriesId: $factoriesId
        }
        page: $page
        limit: $limit
      }
    ) {
      hashMore
      hasNextPage
      hasPrevPage
      limit
      page
      pagingCounter
      totalDocs
      totalPages
      docs {
        _id
        filesId {
          _id
          status
          tags
          filename
          mimeType
          size
          path
          user {
            _id
            fullname
          }
          createdAt
          __typename
        }
        cost {
          supply
          worker
          sum
        }
        maintainCycle {
          ammount
          type
        }
        reportId
        reportDate
        station
        factory
        worker
        status
        workDetailsId {
          _id
          status
          deviceId
          deviceSku
          name
          original
          positionUsing
          description
          reason
          startTime
          endTime
          replaceDevice
          infoAfter
          note
          workingBy
          workId
          worker
          cost {
            supply
            worker
            sum
          }
          filesId {
            _id
            status
            tags
            filename
            mimeType
            size
            path
            user {
              _id
              fullname
            }
          }
        }
        deleted
        workCodeId {
          _id
          name
          code
        }
        deviceGroupId {
          _id
          name
          code
        }
        name
        content
        isAccept
        startDate
        endDate
        ammountUnit
        typeUnit
        note
        cycleMaintainId {
          _id
          name
        }
        createdAt
        updatedAt
        createdBy {
          _id
          fullname
        }
        updatedBy {
          _id
          fullname
        }
      }
    }
  }
`;

export const FIND_WORK_DETAIL = gql`
  query findWorkDetailById($_id: String!) {
    findWorkDetailById(_id: $_id) {
      _id
      name
      original
      description
      reason
      positionUsing
      workingBy
      workId {
        _id
        acceptance {
          filesId {
            _id
            status
            tags
            filename
            mimeType
            size
            path
            user {
              username
              _id
              gender
              phoneNumber
              fullname
              birthDay
              avatar
              deletedAt
              role
              namespace
              factoryId
            }
            createdAt
            updatedAt
          }
          status
          note
        }
        status
        isCycleMaintain
        workDetailsId {
          _id
          status
          deviceSku
          name
          original
          positionUsing
          description
          reason
          startTime
          endTime
          replaceDevice
          infoAfter
          note
          workingBy
          filesId {
            _id
            status
            tags
            filename
            mimeType
            size
            path
            user {
              username
              _id
              gender
              phoneNumber
              fullname
              birthDay
              avatar
              deletedAt
              role
              namespace
              factoryId
            }
            createdAt
            updatedAt
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
