module.exports = Object.freeze({
    EMAIL_SETTING: "メール設定",
    GENERAL_SETTING: "一般設定",
    FROM_EMAIL: "送信元メールアドレス",
    FROM_NAME: "送信者名",
    PORT: "ポート",
    HOST: "ホスト",
    SMTP_AUTHENTICATION: "SMTP認証",
    PASSWORD: "パスワード",
    SAVE: "設定を保存",
    SEND_EMAIL_TEST: "テストメールの送信",
    PROXY: "プロキシ",
    TLS: "TLS",
    SECURITY_NONE: "暗号化なし",
    SECURITY: "セキュリティメソッド",
    SERVER_EMAIL: "サーバーの選択",
    CUSTOM: "カスタム",
    SMTP: "SMTP",
    SERVICE: "サービス",
    GMAIL: "Gmail",
    ZOHO: "Zoho",
    EXIT: "終了",
    INPUT_ERROR: "入力情報が正しくありません",
    NO_RESULTS: '一致する結果がありません',
    NO_DATA: 'データがありません',
    NUMBER_ITEMS: "ページサイズ",
    SEND_TO: "送信先",
    MAILCC: "メール CC",
    TITLE_EMAIL: "タイトル",
    CONTENT: "本文",
    NO_EMAIL: '送信先のメールアドレスが入力されていません',
    NO_EMAIL_OR_ERROR: 'メールアドレスが入力されていないか、正しい形式ではありません',
    VALIDATE_EMAIL_FAIL: 'メールアドレスの形式が正しくありません',
    SENDFROM_ERROR: '送信者の名前は0から50文字でなければなりません',
    PASSWORD_ERROR: 'パスワードは6から20文字でなければなりません',
    EMPTY_ERROR: '未入力の項目があります'
});
