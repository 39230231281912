module.exports = Object.freeze({
    SYSTEM_NAME: "電子管理システム",
    TITLE: "作業するモジュールを選択してください",
    LOGIN: "ログイン",
    HUMAN_RESOURCE: "人事",
    HUMAN_RESOURCE_INTRO: "人事情報、給与、採用...",
    PROPERTY_MAINTENANCE: "資産保守",
    PROPERTY_MAINTENANCE_INTRO: "計画的/緊急なメンテナンス、修理...",
    ELECTRIC_OFFICE: "電子オフィス",
    ELECTRIC_OFFICE_INTRO: "文書管理、文書発行、企業運営...",
    BUSINESS_ADMINISTRATION: "ビジネス管理",
    BUSINESS_ADMINISTRATION_INTRO: "マーケティング、水の供給契約...",
    CLIENT: "お客様",
    CLIENT_INTRO: "顧客サービス、顧客情報...",
    ACCOUNTANT: "会計",
    ACCOUNTANT_INTRO: "債権、財務、給与...",
    SYSTEM: "システム",
    USER_INFORMATION: "ユーザー情報",
    ACCOUNT: "アカウント",
    HOME_PAGE: 'ホームページに戻る',
    ABOUT_US: '紹介ページ',
    LOG_OUT: 'ログアウト',
});
