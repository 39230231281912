import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";

React.icons = icons;
if (process.env.NODE_ENV === "production") {
  window.console.log = () => {};
  window.console.debug = () => {};
  // window.console.info = () => {};
  window.console.warn = () => {};
  window.console.error = () => {};

  console.info(
    "%cHello",
    "color: #0093dd; font-weight: bold, text-transform: uppercase; font-family: 'Open Sans', sans-serif;; font-size: 20px"
  );
  console.info(
    "%cOur product was built base one Typescript, NodeJS, ExpressJS, Apollo Server, MongoDB, Redis and lots of love.\n\nWanna join with us? Click this link 👉 https://www.akb.com.vn/tuyen-dung/",
    "color: #0093dd; font-family: 'Open Sans', sans-serif;; font-size: 13px"
  );
}
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
