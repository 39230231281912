module.exports = Object.freeze({
    STT: "#",
    PAGE_NAME: "画面名",
    PAGE_FUNCTION: "機能",
    SEARCH_PLACEHOLDER: '画面名で検索',
    SAVE_SETTING: '設定を保存',
    RESTORE_SETTING: 'デフォルトに戻す',
    ADD: '追加',
    EDIT: '編集',
    DELETE: '削除 / 復元',
    DOWNLOAD: 'ダウンロード',
    UPLOAD: 'アップロード',
    EXPORT: 'ファイルのエクスポート',
    READ: '表示',
    RESTORE: '復元',
    CHECK_ALL: 'すべて選択'
});
