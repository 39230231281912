import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
  CCardHeader,
  CCardFooter,
  CButton,
} from '@coreui/react';
import { imgs } from '../../assets/image/index';
import { indexGeneral } from '../../language/vn/general/_indexGeneral';

function showQuestions(
  title = indexGeneral.ALERT,
  message = '',
  secondAction = () => {},
  thirdAction = () => {},
  titleOne = indexGeneral.NO,
  titleTwo = indexGeneral.YES
) {
  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
        <CContainer className='border-0'>
          <CRow>
            <CCol md='12'>
              <CCardGroup>
                <CCard
                  className='border-0 rounded-5 shadow-lg mb-5 bg-white rounded'
                  style={{
                    minWidth: '350px',
                    display: 'block',
                    width: window.innerWidth <= 375 ? '355px' : '390px',
                    backgroundColor: '#FFFF',
                  }}
                >
                  <CCardHeader className='mb-0 bg-white'>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <h2 className='mb-0'>{title}</h2>
                    </div>
                  </CCardHeader>

                  <CCardBody
                    style={{ backgroundColor: 'white', alignItems: 'center' }}
                  >
                    <p>{message}</p>
                  </CCardBody>

                  <CCardFooter className='pr-0'>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <CButton
                        type='reset'
                        color='secondary'
                        className='mr-2 float-right'
                        onClick={async () => {
                          await onClose();
                          secondAction();
                        }}
                      >
                        {titleOne}
                      </CButton>
                      <CButton
                        type='reset'
                        style={{ backgroundColor: '#0093dd', color: 'white' }}
                        className='mr-2 float-right'
                        onClick={async () => {
                          await onClose();
                          thirdAction();
                        }}
                      >
                        {titleTwo}
                      </CButton>
                    </div>
                  </CCardFooter>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      );
    },
  });
}

function showAlert(
  title = indexGeneral.ALERT,
  message = '',
  action = () => {},
  check
) {
  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
        <CContainer className='border-0 rounded-lg'>
          <CRow>
            <CCol md='12'>
              <CCardGroup>
                <CCard
                  className='border-1 rounded-5 shadow-lg mb-5 bg-white rounded'
                  style={{
                    minWidth: '350px',
                    display: 'block',
                    width: window.innerWidth <= 375 ? '350px' : '390px',
                    backgroundColor: '#FFFF',
                  }}
                >
                  <CCardBody className='text-center'>
                    <img
                      style={{ height: 90 }}
                      src={check ? imgs.SUCCESS : imgs.WRONG}
                    />

                    <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                      {/* <CIcon name="cilWarning" size="2xl"/> */}
                      <h2>{title}</h2>
                    </div>

                    <p style={{ padding: 15 }}>{message}</p>

                    <CButton
                      type='reset'
                      style={{ borderColor: '#0093dd' }}
                      //color="info"
                      onClick={async () => {
                        await onClose();
                        action();
                      }}
                    >
                      閉じる
                    </CButton>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      );
    },
  });
}

function showOptions(
  title = indexGeneral.ALERT,
  message = '',
  labels = [],
  actionOne = () => {},
  actionTwo = () => {},
  actionThree = () => {},
  titleOne = indexGeneral.NO,
  titleTwo = indexGeneral.YES,
  titleThree = indexGeneral.YES
) {
  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
        <CContainer className='border-0'>
          <CRow>
            <CCol md='12'>
              <CCardGroup>
                <CCard
                  className='border-0 rounded-5 shadow-lg mb-5 bg-white rounded'
                  style={{
                    minWidth: '350px',
                    display: 'block',
                    width: window.innerWidth <= 375 ? '355px' : '390px',
                    backgroundColor: '#FFFF',
                  }}
                >
                  <CCardHeader className='mb-0 bg-white'>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <h2 className='mb-0'>{title}</h2>
                    </div>
                  </CCardHeader>

                  <CCardBody
                    style={{ backgroundColor: 'white', alignItems: 'center' }}
                  >
                    <p>{message}</p>
                  </CCardBody>

                  <CCardFooter className='pr-0'>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {labels.map((label) => (
                        <CButton
                          type='reset'
                          color={label.color}
                          className='mr-2 float-right'
                          onClick={() => {
                            onClose();
                            label.action();
                          }}
                        >
                          {label.title}
                        </CButton>
                      ))}
                      {/* <CButton
                        type='reset'
                        color='secondary'
                        className='mr-2 float-right'
                        onClick={async () => {
                          await onClose();
                          actionOne();
                        }}
                      >
                        {titleOne}
                      </CButton>
                      <CButton
                        type='reset'
                        style={{ backgroundColor: '#0093dd', color: 'white' }}
                        className='mr-2 float-right'
                        onClick={async () => {
                          await onClose();
                          actionTwo();
                        }}
                      >
                        {titleTwo}
                      </CButton> */}
                    </div>
                  </CCardFooter>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      );
    },
  });
}

const DialogUtil = {
  showQuestions,
  showAlert,
  showOptions,
};

export default DialogUtil;
