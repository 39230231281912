import {
    ApolloClient,
    InMemoryCache,
    from
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import { createUploadLink } from 'apollo-upload-client';
import { RefreshTokens } from './utility/utils';
import { onError } from "@apollo/client/link/error";
import promiseToObservable from './utility/promise_to_observable';
import { indexConstant } from './utility/constants/_indexConstants';
import DialogUtil from './views/component/show_confirm_action';
import { indexGeneral } from './language/vn/general/_indexGeneral';
import store from './store'
require('dotenv').config();

const authLink = setContext((_, { headers }) => {
    const token = Cookies.get(indexConstant.ACCESS_TOKEN);
    let menuAlias = '';
    const routers = store.getState().routers;
    const location = window.location.pathname;
    const urlArr = location.split('/').slice(0, 3);
    routers.forEach(element => {
        var routerUrl = element.path.split('/').slice(0, 3);
        if (JSON.stringify(routerUrl) === JSON.stringify(urlArr)) {
            menuAlias = element.menuAlias;
        }
    });

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : null,
            'menuAlias': menuAlias
        }
    }
})

const httpLink = createUploadLink({ uri: `${process.env.REACT_APP_SERVER_URI}${process.env.REACT_APP_GRAPHQL_URI}` });
console.log('httpLink--------------', httpLink);
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            switch (err.message) {
                case 'Unauthorized':
                    return promiseToObservable(RefreshTokens()).flatMap(({ accessToken }) => {
                        let oldHeaders = operation.getContext().headers;
                        operation.setContext({
                            headers: {
                                ...oldHeaders,
                                authorization: `Bearer ${accessToken}`,
                            },
                        });
                        return forward(operation);
                    })
                default:
                    break;
            }
        }
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        return DialogUtil.showAlert(indexGeneral.ALERT, indexGeneral.CONNECTION_ERROR, () => {
            window.location.assign('/');
        })
    }
});
const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false
    }),
    link: authLink.concat(from([errorLink, httpLink]))
});


export default client