import { gql } from '@apollo/client';
export const ADD_CYCLE_MAINTAIN = gql`
  mutation ($input: AddCycleMaintainInput!, $files: [Upload]) {
    addCycleMaintain(input: $input, files: $files) {
      _id
      name
    }
  }
`;
export const UPDATE_CYCLE_MAINTAIN = gql`
  mutation ($_id: String!, $input: AddCycleMaintainInput!, $files: [Upload]) {
    updateCycleMaintain(_id: $_id, input: $input, files: $files) {
      _id
      name
    }
  }
`;

export const DELETE_CYCLE_MAINTAIN = gql`
  mutation deleteCycleMaintain($_id: String!) {
    deleteCycleMaintain(_id: $_id)
  }
`;

export const ADD_CYCLE_WORK = gql`
  mutation ($input: AddWorkInput!, $files: [Upload]) {
    addCycleWork(input: $input, files: $files) {
      _id
    }
  }
`;

export const EDIT_CYCLE_WORK = gql`
  mutation ($_id: String!, $input: AddWorkInput!, $files: [Upload]) {
    updateCycleWork(_id: $_id, input: $input, files: $files) {
      _id
    }
  }
`;

export const DELETE_CYCLE_WORK = gql`
  mutation deleteWork($_id: String!) {
    deleteWork(_id: $_id)
  }
`;

export const CHANGE_STATUS = gql`
  mutation changeStatusWork($_id: String!, $status: EnumStatusWork!) {
    changeStatusWork(_id: $_id, status: $status) {
      _id
    }
  }
`;

export const ADD_WORK_DETAIl = gql`
  mutation addWorkDetail($_id: String!, $inputs: [AddWorkingDetailInput]) {
    addWorkDetail(_id: $_id, inputs: $inputs)
  }
`;

export const ACCEPT_WORK = gql`
  mutation acceptWork($_id: String!) {
    acceptWork(_id: $_id) {
      _id
      name
    }
  }
`;

export const CHANGE_STATUS_CYCLE = gql`
  mutation changeStatusCycleMaintain(
    $_id: String!
    $status: EnumStatusCycleMaintain!
  ) {
    changeStatusCycleMaintain(_id: $_id, status: $status)
  }
`;

export const GET_LINK_CYCLE_MAINTAIN = gql`
  mutation getLinkExportCycleMaintain(
    $search: String
    $status: String
    $startDate: DateTime
    $endDate: DateTime
    $type: Int
  ) {
    getLinkExportCycleMaintain(
      searchOptions: {
        search: $search
        status: $status
        startDate: $startDate
        endDate: $endDate
        type: $type
      }
    )
  }
`;

export const GET_LINK_EMERGENCY = gql`
  mutation getLinkExportEmergency(
    $search: String
    $status: [EnumAllStatusWork]
    $factoriesId: [String]
    $stationsId: [String]
    $startDate: DateTime
    $endDate: DateTime
    $workCodesId: [String]
    $type: Int
    $isCycleMaitain: Int
    $isAccept: Int
  ) {
    getLinkExportEmergency(
      searchOptions: {
        search: $search
        status: $status
        factoriesId: $factoriesId
        stationsId: $stationsId
        startDate: $startDate
        endDate: $endDate
        workCodesId: $workCodesId
        type: $type
        isCycleMaitain: $isCycleMaitain
        isAccept: $isAccept
      }
    )
  }
`;
export const GET_LINK_STATIC = gql`
  mutation getLinkExportStatisticMaintain(
    $search: String
    $status: [EnumAllStatusWork]
    $factoriesId: [String]
    $stationsId: [String]
    $startDate: DateTime
    $endDate: DateTime
    $cycleMaintainId: String
    $type: Int
    $isPublic: Boolean
    $isAccept: Int
    $createdBy: [String]
  ) {
    getLinkExportStatisticMaintain(
      searchOptions: {
        search: $search
        status: $status
        factoriesId: $factoriesId
        stationsId: $stationsId
        startDate: $startDate
        endDate: $endDate
        cycleMaintainId: $cycleMaintainId
        type: $type
        isAccept: $isAccept
        createdBy: $createdBy
        isPublic: $isPublic
      }
    )
  }
`;
export const GET_LINK_DETAIL = gql`
  mutation getLinkExportCycle(
    $_id: String!
    $search: String
    $status: [EnumAllStatusWork]
    $factoriesId: [String]
    $stationsId: [String]
    $startDate: DateTime
    $endDate: DateTime
    $workCodesId: [String]
    $deviceGroupId: [String]
    $type: Int
    $isAccept: Int
  ) {
    getLinkExportCycle(
      _id: $_id
      searchOptions: {
        search: $search
        status: $status
        factoriesId: $factoriesId
        stationsId: $stationsId
        startDate: $startDate
        endDate: $endDate
        workCodesId: $workCodesId
        type: $type
        deviceGroupId: $deviceGroupId
        isAccept: $isAccept
      }
    )
  }
`;

export const DUPLICATE_CYCLE_MAINTAIN = gql`
  mutation duplicateCycleMaintain(
    $_id: String!
    $input: AddCycleMaintainInput!
  ) {
    duplicateCycleMaintain(_id: $_id, input: $input) {
      _id
      name
    }
  }
`;

export const IMPORT_FILE_MAINTAIN = gql`
  mutation (
    $input: AddCycleMaintainInput!
    $files: [Upload]
    $importFile: Upload!
  ) {
    importCycleMaintain(input: $input, files: $files, importFile: $importFile)
  }
`;
