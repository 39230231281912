import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import "./scss/style.scss";
import { ApolloProvider } from "@apollo/client";
import Cookies from "js-cookie";
import Splash from "./views/pages/general/splash/splash";
import { RefreshTokens, GetUser, GetMenu, getOutSide } from "./utility/utils";
import DialogUtil from "./views/component/show_confirm_action";
import { indexGeneral } from "./language/vn/general/_indexGeneral";
import { indexConstant } from "./utility/constants/_indexConstants";
import client from "./client";
import { useDispatch, useSelector } from "react-redux";

require("dotenv").config();

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Funtions
const Functions = React.lazy(() =>
  import("./views/pages/general/system_category/system_category")
);

// Containers
const TheLayout = React.lazy(() =>
  import("./views/containers/content/layouts")
);

// Pages
const Login = React.lazy(() => import("./views/pages/users/login/login"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/users/forgot_password/forgot_password")
);
const Error = React.lazy(() => import("./views/pages/error/error"));

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [isAuthorizated, setAuthorization] = useState();
  const [pathLogin, setPathLogin] = useState("");
  const [pathForgot, setPathForgot] = useState("");
  const [pathLayout, setPathLayout] = useState("");
  const routerLink = useSelector((state) => state.routerLink);

  useEffect(() => {
    if (routerLink) {
      routerLink.forEach((element) => {
        switch (element.function) {
          case "LOGIN":
            setPathLogin(element.to);
            break;
          case "FORGOT_PASSWORD":
            setPathForgot(element.to);
            break;
          // case "FUNCTIONS":
          //   setPathFunction(element.to)
          //   break;
          case "THE_LAYOUT":
            setPathLayout(element.to);
            break;
          default:
            break;
        }
      });
    }
  });

  useEffect(() => {
    if (isLoading) {
      if (Cookies.get(indexConstant.ACCESS_TOKEN)) {
        GetUser().then(async (res) => {
          if (res.dataError || res.SeverError) {
            res.dataError
              ? await RefreshTokens().then(async (res) => {
                  if (res.isError || !res.accessToken) {
                    getOutSide().then(async (isOutSide) => {
                      dispatch({ type: "routerLink", routerLink: isOutSide });
                      setAuthorization(false);
                      if (window.location.pathname !== "/login") {
                        console.log(
                          "parameter ",
                          window.location.pathname + window.location.search
                        );
                        dispatch({
                          type: "prevLink",
                          prevLink: window.location.pathname,
                        });
                        dispatch({
                          type: "paramLink",
                          paramLink: window.location.search,
                        });
                      }
                    });
                  } else {
                    await GetUser().then(async (res) => {
                      if (res.SeverError) {
                        DialogUtil.showAlert(
                          indexGeneral.ALERT,
                          indexGeneral.CONNECTION_ERROR,
                          () => {
                            window.location.assign("/");
                          }
                        );
                      } else {
                        dispatch({ type: "user", userData: res?.dataUser });
                        let isMenu = await GetMenu();
                        if (isMenu) {
                          // let routers = CryptoJS.AES.encrypt(isMenu?.routers, process.env.REACT_APP_ENCRYPT_KEY)?.toString();
                          // Cookies.set("routers", routers);
                          // let navigation = CryptoJS.AES.encrypt(isMenu?.navigation, process.env.REACT_APP_ENCRYPT_KEY)?.toString();
                          // Cookies.set("navigation", navigation);
                          dispatch({ type: "route", routers: isMenu?.routers });
                          dispatch({
                            type: "navigation",
                            navigation: isMenu?.navigation,
                          });
                          dispatch({
                            type: "routerLink",
                            routerLink: isMenu?.routerLink,
                          });
                        }
                        setAuthorization(true);
                      }
                    });
                  }
                })
              : DialogUtil.showAlert(
                  indexGeneral.ALERT,
                  indexGeneral.CONNECTION_ERROR,
                  () => {
                    window.location.assign("/");
                  }
                );
          } else {
            dispatch({ type: "user", userData: res?.dataUser });
            let isMenu = await GetMenu();
            if (isMenu) {
              // let routers = CryptoJS.AES.encrypt(JSON.stringify(isMenu?.routers), process.env.REACT_APP_ENCRYPT_KEY)?.toString();
              // Cookies.set("routers", routers);
              // let navigation = CryptoJS.AES.encrypt(JSON.stringify(isMenu?.navigation), process.env.REACT_APP_ENCRYPT_KEY)?.toString();
              // Cookies.set("navigation", navigation);
              dispatch({ type: "route", routers: isMenu?.routers });
              dispatch({ type: "navigation", navigation: isMenu?.navigation });
              dispatch({ type: "routerLink", routerLink: isMenu?.routerLink });
            }
            setAuthorization(true);
          }
        });
      } else {
        getOutSide().then(async (isOutSide) => {
          dispatch({ type: "routerLink", routerLink: isOutSide });
          setAuthorization(false);
          if (window.location.pathname !== "/login") {
            console.log(
              "parameter ",
              window.location.pathname + window.location.search
            );
            dispatch({ type: "prevLink", prevLink: window.location.pathname });
            dispatch({ type: "paramLink", paramLink: window.location.search });
          }
        });
      }
    }
    return () => {
      setLoading(false);
    };
  }, [isAuthorizated]);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          {isLoading ? (
            <Splash />
          ) : !isAuthorizated ? (
            <Switch key="dangnhap">
              <Route
                path={pathLogin}
                name="Login Page"
                render={() => <Login />}
              />
              <Route
                path={`${pathForgot}/:token`}
                name="Reset Password"
                render={() => <ForgotPassword />}
              />
              <Route
                path={pathForgot}
                name="ForgotPassword Page"
                render={() => <ForgotPassword />}
              />
              <Redirect from="*" exact to={pathLogin} />
              {/* <Route path='*' exact={true} render={() => <Login />} /> */}
            </Switch>
          ) : (
            <Switch key="functions">
              <Route
                path={pathLayout}
                name="Dashboard"
                render={() => <TheLayout />}
              />
              {/* <Route exact path={pathFunction} name="Functions Page" render={() => <Functions />} /> */}
              <Redirect from="/" exact to={pathLayout} />
              <Redirect from={pathLogin} exact to={pathLayout} />
              <Redirect from={pathForgot} exact to={pathLayout} />
              <Route path="*" exact={true} render={() => <Error />} />
            </Switch>
          )}
        </React.Suspense>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
