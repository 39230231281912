import { gql } from "@apollo/client";
export const GET_USER = gql`
  query {
      getUser {
        id
        emails {
          address
          verified
        }
        username
        phoneNumber
        fullname
        address {
          detail
          ward {
            code
            name
          }
          district {
            code
            name
          }
          city {
            code
            name
          }
        }
        birthDay
        avatar
        deletedAt
        role
        gender
        factoryId
      }

  }
`;

export const GET_ACCOUNT = gql`
  query getAccount($search: String, $status: Int, $factoryId: String, $stationId: String, $page: Int, $limit: Int) {
    getAccount(searchUser: {
      searchOptions: {
        search: $search,
        status: $status,
        factoryId: $factoryId,
        stationId: $stationId
      },
      page: $page,
      limit: $limit,
    }){
      _id
      username
      fullname
      gender
      role
      stationId {
        _id
        name
      }
      factoryId {
        _id
        name
        type
      }
      isActive
    }
  }
`;

export const GET_USER_PAGE = gql`
  query getUserPaginate(
    $search: String, 
    $status: Int,
    $factoryId: String,
    $page: Int,
    $limit: Int, 
  ) {
    getUserPaginate(
      searchUser: {
        searchOptions: {
          search: $search,
          status: $status,
          factoryId: $factoryId
        }
        page: $page,
        limit: $limit,
      }
    )
  }
`;

export const EXPORT_USER = gql`
  query getLinkExportEmployee(
    $search: String, 
    $status: Int,
    $factoryId: String,
    ){
    getLinkExportEmployee(
      searchUser: {
          search: $search,
          status: $status,
          factoryId: $factoryId
        }
    )
  }
`;

export const GET_USER_TRANSFER = gql`
query getPaginateUserTransfer(
    $searchOption: SearchPaginateUserTransfer!
  ){
  getPaginateUserTransfer(
    searchOption: $searchOption
  ) {
    hashMore
    hasNextPage
    hasPrevPage
    limit
    page
    pagingCounter
    totalDocs
    totalPages
    docs {
      _id
      confirmId
      confirmDate
      certificateId
      certificateDate
      reason
      content
      confirmUserId
      isConfirm
      transfer_histories {
          _id
          userId {
        		_id
        		fullname
        		username
      		}
          roleCurrentAlias
          factoryCurrentId {
            _id
            name
            type
          } 
          stationCurrentId {
            _id
            name
          }
          factoryToId {
            _id
            name
            type
          }
          stationToId {
            _id
            name
          }
          roleToAlias
          note
          userTransferId {
            _id
            fullname
            username
          }
          deleted
      }
      filesId {
        _id
        status
        tags
        filename
        mimeType
        size
        path
        user {
          _id
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
     	createdBy {
        _id
        fullname
        username
      }
      updatedBy {
        _id
        fullname
        username
      }
      deleted
      deletedAt
    }
  }
}
`;