import { gql } from '@apollo/client';
export const ADD_DEVICE = gql`
  mutation ($input: AddDeviceInput!, $files: [Upload]) {
    addDevice(input: $input, files: $files) {
      _id
      name
      sku
      info
      installTime
      product {
        model
        original
        year
      }
      status
      maintainCycle {
        ammount
        type
      }
      note
      responsibleBy
      serial
      assetCodeId {
        _id
        name
        code
      }
      stationId {
        _id
        name
        factory
      }
      factoryId {
        _id
        name
      }
      createdAt
      updatedAt
      deletedAt
      deleted
    }
  }
`;

export const UPDATE_DEVICE = gql`
  mutation updateDevice(
    $input: AddDeviceInput!
    $_id: String!
    $files: [Upload]
  ) {
    updateDevice(input: $input, files: $files, _id: $_id) {
      _id
      name
    }
  }
`;

export const DELETE_DEVICE = gql`
  mutation deleteDevice($_id: String!) {
    deleteDevice(_id: $_id)
  }
`;

export const ADD_DEVICE_HISTORY = gql`
  mutation addDeviceHistory($input: AddDeviceHistoryInput!, $files: [Upload]) {
    addDeviceHistory(input: $input, files: $files) {
      _id
      deviceId {
        _id
        name
        sku
      }
      status
      workId {
        _id
        name
      }
    }
  }
`;

export const ADD_OPERATING_DIARY = gql`
  mutation addOperatingDiary($input: AddDeviceOperatingDiary!, $files: [Upload]) {
    addOperatingDiary(input: $input, files: $files) {
      _id
      deviceId {
        _id
        name
        sku
      }
    }
  } 
`;

export const UPDATE_DEVICE_HISTORY = gql`
  mutation updateDeviceHistory(
    $input: AddDeviceHistoryInput!
    $_id: String!
    $files: [Upload]
  ) {
    updateDeviceHistory(input: $input, _id: $_id, files: $files) {
      _id
      deviceId {
        _id
        name
        sku
      }
      status
      workId {
        _id
        name
      }
    }
  }
`;

export const UPDATE_OPERATING_DIARY = gql`
  mutation updateOperatingDiary(
    $_id: String!
    $input: AddDeviceOperatingDiary!,
    $files: [Upload]
  ) {
    updateOperatingDiary(input: $input, _id: $_id, files: $files) {
      _id
      name
      deviceSku
      original
      positionUsing
      time_start
      time_stop
      note
    }
  }
`;

export const DELETE_DEVICE_HISTORY = gql`
  mutation deleteDeviceHistory($_id: String!) {
    deleteDeviceHistory(_id: $_id)
  }
`;

export const DELETE_OPERATING_DIARY = gql`
  mutation deleteOperatingDiary($_id: String!) {
    deleteOperatingDiary(_id: $_id)
  }
`;

export const GET_LINK_OPERATING_DIARY = gql`
  mutation getLinkExportDeviceOperatingDiary(
    $search: String
    $devicesId: [String]
    $factoriesId: [String]
    $stationsId: [String]
    $startTime: DateTime
    $endTime: DateTime
  ) {
    getLinkExportDeviceOperatingDiary(
      searchOptions: {
        search: $search
        devicesId: $devicesId
        factoriesId: $factoriesId
        stationsId: $stationsId
        startTime: $startTime
        endTime: $endTime
      }
    )
  }
`;

export const ADD_DEVICE_SCHEDULE = gql`
  mutation addDeviceScheduler($input: AddDeviceScheduler!) {
    addDeviceScheduler(input: $input) {
      _id
      deviceId {
        _id
        name
        sku
      }
      content
      type
      startTime
      endTime
      nextTime
      isRunning
      isActive
      createdAt
      updatedAt
      createdBy
      updatedBy
      deleted
      deletedBy
    }
  }
`;

export const UPDATE_DEVICE_SCHEDULE = gql`
  mutation updateDeviceScheduler($_id: String!, $input: AddDeviceScheduler!) {
    updateDeviceScheduler(_id: $_id, input: $input) {
      _id
      deviceId {
        _id
        name
        sku
      }
      content
      type
      startTime
      endTime
      nextTime
      isRunning
      isActive
      createdAt
      updatedAt
      createdBy
      updatedBy
      deleted
      deletedBy
    }
  }
`;

export const DELETE_DEVICE_SCHEDULE = gql`
  mutation deleteDeviceScheduler($_id: String!) {
    deleteDeviceScheduler(_id: $_id)
  }
`;

export const ADD_DEVICE_TRANSFER = gql`
  mutation ($input: AddDeviceTransferInput!, $files: [Upload]) {
    addDeviceTransfer(input: $input, files: $files) {
      _id
      confirmId
      confirmDate
      certificateId
      certificateDate
      reason
      content
      confirmUserId
      isConfirm
      transfer_histories {
        _id
        deviceId {
          _id
          name
          sku
        }
        factoryCurrentId {
          _id
          name
        }
        stationCurrentId {
          _id
          name
        }
        factoryToId {
          _id
          name
        }
        stationToId {
          _id
          name
        }
        note
        deviceTransferId
      }
      createdAt
      updatedAt
      createdBy {
        _id
        fullname
        username
      }
      updatedBy {
        _id
        fullname
        username
      }
      deleted
      deletedAt
    }
  }
`;

export const UPDATE_DEVICE_TRANSFER = gql`
  mutation ($_id: String!, $input: AddDeviceTransferInput!, $files: [Upload]) {
    updateDeviceTransfer(_id: $_id, input: $input, files: $files) {
      _id
      confirmId
      confirmDate
      certificateId
      certificateDate
      reason
      content
      confirmUserId
      isConfirm
      transfer_histories {
        _id
        deviceId {
          _id
          name
          sku
        }
        factoryCurrentId {
          _id
          name
        }
        stationCurrentId {
          _id
          name
        }
        factoryToId {
          _id
          name
        }
        stationToId {
          _id
          name
        }
        note
        deviceTransferId
      }
      createdAt
      updatedAt
      createdBy {
        _id
        fullname
        username
      }
      updatedBy {
        _id
        fullname
        username
      }
      deleted
      deletedAt
    }
  }
`;

export const DELETE_DEVICE_TRANSFER = gql`
  mutation deleteDeviceTransfer($_id: String!) {
    deleteDeviceTransfer(_id: $_id)
  }
`;

export const CONFIRM_DEVICE_TRANSFER = gql`
  mutation confirmDeviceTransfer($_id: String!) {
    confirmDeviceTransfer(_id: $_id)
  }
`;

export const GET_LINK_HISTORY = gql`
  mutation getLinkExportDeviceHistory(
    $search: String
    $devicesId: [String]
    $factoriesId: [String]
    $stationsId: [String]
    $startTime: DateTime
    $endTime: DateTime
    $status: [EnumSearchDeviceHistory]
    $workingsBy: [String]
    $type: Int
  ) {
    getLinkExportDeviceHistory(
      searchOptions: {
        search: $search
        devicesId: $devicesId
        factoriesId: $factoriesId
        stationsId: $stationsId
        startTime: $startTime
        endTime: $endTime
        status: $status
        workingsBy: $workingsBy
        type: $type
      }
    )
  }
`;
