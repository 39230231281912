const validateEmail = (email) => {
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEX.test(email) && email?.length <= 50;
};

const validatePassWord = (password) => {
  password = password?.trim();
  return password?.length >= 6 && password?.length <= 20;
};

const validateUserName = (username) => {
  const UserName_REGEX = {
    expect: /[ ]/,
  };
  return !UserName_REGEX.expect.test(username) && username.length >= 4;
};
const validateImage = (image) => {
  const Image_REGEX = /\.(jpg|jpeg|png|gif)$/;
  return Image_REGEX.test(image);
};

const validatePhone = (phone) => {
  const re = /^\+?([0-9]{1,5})\)?[-. ]?([0-9]{1,5})[-. ]?([0-9]{1,5})$/;
  return re.test(phone);
};

const isInt = (value) => {
  const er = /^-?[0-9]|[-/]+$/;
  return er.test(value);
};

const isLetter = (str) => {
  return str?.length === 1 && str.match(/[a-z]/i);
};

const convertViToEn = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  );

  // chữ thủy không đọc được.
  return str;
};

const getExtension = (filename) => {
  var parts = filename.split('.');
  return parts[parts.length - 1];
};

const getIconFileStyle = (filename) => {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'pdf':
      return 'fa fa-file-pdf-o fa-lg';
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
      return 'fa fa-file-picture-o fa-lg';
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
      return 'fa fa-film fa-lg';
    case 'xlsx':
    case 'xls':
    case 'xltx':
    case 'xls':
    case 'xml':
    case 'xlt':
    case 'xlw':
      return 'fa fa-file-excel-o fa-lg';
    case 'doc':
    case 'docx':
    case 'dot':
    case 'odt':
    case 'txt':
    case 'dotm':
    case 'dotx':
      return 'fa fa-file-word-o fa-lg';
    case 'mp3':
    case 'm4a':
    case 'm4b':
    case 'wma':
    case 'msv':
      return 'fa fa-file-sound-o fa-lg';
    case 'zip':
    case 'rar':
    case 'raw':
      return 'fa fa-file-zip-o fa-lg';
  }
  return 'fa fa-file-o fa-lg';
};

const validateDateInput = (date) => {
  const DATE_REGEX = /^-?[0-9]|[-/]+$/;
  return DATE_REGEX.test(date);
};

const checkMaxLength = (data, max = 0) => {
  return data?.length >= max ? true : false;
};

const isEmpty = (data) => {
  if (typeof data === 'string') {
    return data.trim().length === 0;
  }
  if (Array.isArray(data)) {
    return data.filter((item) => item).length === 0;
  }
  if (typeof data === 'boolean' && !data) {
    return true;
  }
  if (typeof data === 'undefined' || data === null) {
    return true;
  }
  return false;
  //   switch (data) {
  //     case 0:
  //     case '0':
  //     case null:
  //     case false:
  //       return true;
  //     default:
  //       return typeof data === 'undefined';
  //   }
};

const validateIdentifyCode = (code) => {
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  var regex = /^[a-zA-Z0-9@._-]+$/g;
  return !format.test(code) && regex;
};

const isDateTime = (value) => {
  var dateWrapper = new Date(value);
  return !isNaN(dateWrapper.getDate());
};

export {
  validateEmail,
  validateUserName,
  validatePassWord,
  validateImage,
  convertViToEn,
  validatePhone,
  isInt,
  isLetter,
  getIconFileStyle,
  validateDateInput,
  isEmpty,
  checkMaxLength,
  validateIdentifyCode,
  isDateTime,
};
