module.exports = Object.freeze({
   FILE_STORAGE: '文書管理',
   SENDER: '送信者',
   FROM_DATE: '開始日',
   TO_DATE: '終了日',
   SENT_DATE: '送信日',
   PERIOD_OF_TIME: '期間の選択',
   READ_DOCUMENT: 'プレビュー',
   DOWNLOAD_DOCUMENT: 'ダウンロード',
   ASK_DOWNLOAD: 'ファイルのダウンロードを続行しますか？',
   ASK_DOWNLOAD_QRCODE: 'QRコードのダウンロードを続行しますか？',
   NO_DATA_TO_EXPORT: 'エクスポートするデータがありません',
   NO_FILE: '文書がありません',
});
