import { gql } from '@apollo/client';

export const GET_DEVICE_ALL = gql`
  query getDeviceAll(
    $factoriesId: [String]
    $stationsId: [String]
    $search: String
    $status: [EnumDeviceStatus]
  ) {
    getDeviceAll(
      search: $search
      factoriesId: $factoriesId
      stationsId: $stationsId
      status: $status
    ) {
      _id
      name
      sku
      info
      installTime
      product {
        model
        original
        year
      }
      status
      maintainCycle {
        ammount
        type
      }
      note
      namspace
      responsibleBy
      serial
      assetCodeId {
        _id
        name
        code
      }
      stationId {
        _id
        name
        factory
      }
      factoryId {
        _id
        name
      }
      filesId {
        _id
        filename
      }
      replaceFor
      replaceReason
      isReplaceDevice
      createdAt
      updatedAt
      deletedAt
      deleted
    }
  }
`;

export const GET_DEVICE_ALL_SHORT = gql`
  query getDeviceAll(
    $factoriesId: [String]
    $stationsId: [String]
    $status: [EnumDeviceStatus]
  ) {
    getDeviceAll(
      factoriesId: $factoriesId
      stationsId: $stationsId
      status: $status
    ) {
      _id
      name
      sku
      status
      stationId {
        _id
      }
      factoryId {
        _id
      }
    }
  }
`;

export const LIST_SUPPLIES = gql`
  query getDevicePaginate(
    $search: String
    $code: [String]
    $factoriesId: [String]
    $stationsId: [String]
    $startDate: DateTime
    $endDate: DateTime
    $status: [EnumDeviceStatus]
    $page: Int
    $limit: Int
    $type: Int
  ) {
    getDevicePaginate(
      search: $search
      code: $code
      factoriesId: $factoriesId
      stationsId: $stationsId
      startDate: $startDate
      status: $status
      endDate: $endDate
      type: $type
      page: $page
      limit: $limit
    ) {
      hashMore
      hasNextPage
      hasPrevPage
      limit
      page
      pagingCounter
      totalDocs
      totalPages
      docs {
        _id
        name
        sku
        info
        installTime
        product {
          model
          original
          year
        }
        status
        maintainCycle {
          ammount
          type
        }
        note
        namspace
        responsibleBy
        serial
        assetCodeId {
          _id
          name
          code
        }
        stationId {
          _id
          name
          factory
        }
        factoryId {
          _id
          name
        }
        filesId {
          _id
          status
          tags
          filename
          mimeType
          size
          path
          user {
            emails {
              address
              verified
            }
            username
            _id
            phoneNumber
            fullname
            birthDay
            avatar
            factoryId
            stationId
          }
          createdAt
          updatedAt
        }
        replaceFor
        replaceReason
        isReplaceDevice
        createdAt
        updatedAt
        createdBy
        updatedBy
        deletedAt
        deleted
        operatingTime
        resetTime
        remainingTime
        flag
        totalOperating
        timeCalcutate
      }
    }
  }
`;

export const EXPORT_DEVICE = gql`
  query getLinkExportDevice(
    $search: String
    $code: [String]
    $factoriesId: [String]
    $stationsId: [String]
    $startDate: DateTime
    $endDate: DateTime
    $status: [EnumDeviceStatus]
    $type: Int
  ) {
    getLinkExportDevice(
      search: $search
      code: $code
      factoriesId: $factoriesId
      stationsId: $stationsId
      startDate: $startDate
      endDate: $endDate
      status: $status
      type: $type
    )
  }
`;

export const EXPORT_QRCODE = gql`
  query exportQrCodeDevice(
    $search: String
    $code: [String]
    $factoriesId: [String]
    $stationsId: [String]
    $startDate: DateTime
    $endDate: DateTime
    $status: [EnumDeviceStatus]
    $type: Int
  ) {
    exportQrCodeDevice(
      search: $search
      code: $code
      factoriesId: $factoriesId
      stationsId: $stationsId
      startDate: $startDate
      endDate: $endDate
      status: $status
      type: $type
    )
  }
`;

export const GET_ALL_DEVICE = gql`
  query getDeviceAll(
    $search: String
    $code: [String]
    $stationsId: [String]
    $factoriesId: [String]
  ) {
    getDeviceAll(
      search: $search
      code: $code
      stationsId: $stationsId
      factoriesId: $factoriesId
    ) {
      _id
      name
      sku
      product {
        model
        original
        year
      }
      stationId {
        _id
        name
        factory
      }
      factoryId {
        _id
        name
      }
    }
  }
`;

export const GET_DEVICE_HISTORY = gql`
  query getPaginateDeviceHistory(
    $devicesId: [String]
    $factoriesId: [String]
    $stationsId: [String]
    $search: String
    $status: [EnumSearchDeviceHistory]
    $startTime: DateTime
    $endTime: DateTime
    $workingsBy: [String]
    $limit: Int
    $page: Int
    $type: Int
  ) {
    getPaginateDeviceHistory(
      searchDeviceHistory: {
        searchOptions: {
          devicesId: $devicesId
          factoriesId: $factoriesId
          stationsId: $stationsId
          search: $search
          status: $status
          startTime: $startTime
          endTime: $endTime
          workingsBy: $workingsBy
          type: $type
        }
        limit: $limit
        page: $page
      }
    ) {
      hashMore
      hasNextPage
      hasPrevPage
      limit
      page
      pagingCounter
      totalDocs
      totalPages
      docs {
        _id
        filesId {
          _id
          status
          tags
          filename
          mimeType
          size
          path
          user {
            emails {
              address
              verified
            }
            username
            _id
            gender
            phoneNumber
            fullname
            birthDay
            avatar
            deletedAt
            role
            namespace
            factoryId
          }
          createdAt
          updatedAt
        }
        deviceId {
          _id
          name
          sku
        }
        status
        workId {
          _id
          name
        }
        name
        deviceSku
        original
        positionUsing
        workName
        startTime
        endTime
        replaceDevice
        reason
        note
        cycleMaintainId {
          _id
          name
        }
        cost {
          supply
          worker
          sum
        }
        description
        infoAfter
        isAuto
        worker
        workingBy {
          username
          _id
          fullname
          factoryId
        }
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  }
`;

export const GET_OPERATING_DIARY = gql`
query getPaginateOperatingDiary(
  $search: String
  $devicesId: [String]
  $factoriesId: [String]
  $stationsId: [String]
  $startTime: DateTime
  $endTime: DateTime
  $limit: Int
  $page: Int
) {
  getPaginateOperatingDiary(
    searchOperatingDiary: {
      searchOptions: {
        search: $search
        devicesId: $devicesId
        factoriesId: $factoriesId
        stationsId: $stationsId
        startTime: $startTime
        endTime: $endTime
      }
      limit: $limit
      page: $page
    }
  ) {
    hashMore
    hasNextPage
    hasPrevPage
    limit
    page
    pagingCounter
    totalDocs
    totalPages
    docs {
      _id
      deviceId {
        _id
        name
      }
      filesId {
        _id
        status
        tags
        filename
        mimeType
        size
        path
        user {
          username
          _id
        }
        createdAt
        updatedAt
      }
      name
      deviceSku
      original
      positionUsing
      time_start
      time_stop
      note
      worker
      createdBy
      updatedBy
      createdAt
      updatedAt
      deleted
      deletedAt
      content
      factoryId
      stationId
    }
  }
}
`;

export const GET_DEVICE_SCHEDULE = gql`
  query getDeviceSchedulerByDeviceId($deviceId: String!) {
    getDeviceSchedulerByDeviceId(deviceId: $deviceId) {
      _id
      deviceId {
        _id
        name
        sku
      }
      content
      type
      startTime
      endTime
      nextTime
      isRunning
      isAuto
      isActive
      createdAt
      updatedAt
      createdBy
      updatedBy
      deleted
      deletedBy
    }
  }
`;

export const STATISTIC_DEVICES = gql`
  query {
    statisticDevices {
      type
      factoryName
      stationName
      totalDevices
      workDevices
      stopDevices
    }
  }
`;

export const GET_DEVICE_TRANSFER = gql`
  query getPaginateDeviceTransfer(
    $searchOption: SearchPaginateDeviceTransfer!
  ) {
    getPaginateDeviceTransfer(searchOption: $searchOption) {
      hashMore
      hasNextPage
      hasPrevPage
      limit
      page
      pagingCounter
      totalDocs
      totalPages
      docs {
        _id
        confirmId
        confirmDate
        certificateId
        certificateDate
        reason
        content
        confirmUserId
        isConfirm
        filesId {
          _id
          status
          tags
          filename
          mimeType
          size
          path
          user {
            username
            _id
          }
          createdAt
          updatedAt
        }
        transfer_histories {
          _id
          deviceId {
            _id
            name
            sku
          }
          factoryCurrentId {
            _id
            name
          }
          stationCurrentId {
            _id
            name
          }
          factoryToId {
            _id
            name
          }
          stationToId {
            _id
            name
          }
          note
          deviceTransferId
          deleted
        }
        createdAt
        updatedAt
        createdBy {
          _id
          fullname
          username
        }
        updatedBy {
          _id
          fullname
          username
        }
        deleted
        deletedAt
      }
    }
  }
`;

export const FIND_DEVICE = gql`
  query findDeviceById($_id: String!) {
    findDeviceById(_id: $_id) {
      _id
      name
      sku
      info
      installTime
      product {
        model
        original
        year
      }
      status
      maintainCycle {
        ammount
        type
      }
      note
      namspace
      responsibleBy
      serial
      assetCodeId {
        _id
        name
        code
      }
      stationId {
        _id
        name
        factory
      }
      factoryId {
        _id
        name
      }
      filesId {
        _id
        status
        tags
        filename
        mimeType
        size
        path
        user {
          id
          emails {
            address
            verified
          }
          username
          _id
          gender
          phoneNumber
          fullname
          avatar
          role
          factoryId
          stationId
        }
        createdAt
        updatedAt
      }
      replaceFor
      replaceReason
      isReplaceDevice
      createdAt
      updatedAt
      createdBy
      updatedBy
      operatingTime
      resetTime
    }
  }
`;

export const STATISTIC_OPERATING_DiARY = gql`
  query statisticOperatingDiary(
    $_id: String!,
  ){
    statisticOperatingDiary(
      _id: $_id
    )
  }
`;

export const OPERATING_DiARY_DEVICE = gql`
  query operatingDiaryDevice(
    $_id: String!,
  ){
    operatingDiaryDevice(
      _id: $_id
    )
  }
`;

export const STATISTIC_TIME_OPERATING_DiARY = gql`
  query staticTimeOperatingDiary(
    $devicesId: String
    $time_start: DateTime
    $time_stop: DateTime
  ) {
    staticTimeOperatingDiary(
      searchOptions: {
        devicesId: $devicesId,
        time_start: $time_start,
        time_stop: $time_stop,
      }
    )
  }
`;

export const STATISTIC_SESSION_TIME = gql`
  query staticSessionOperatingDiary(
    $devicesId: String
    $time_start: DateTime
    $time_stop: DateTime
  ) {
    staticSessionOperatingDiary(
      searchOptions: {
        devicesId: $devicesId,
        time_start: $time_start,
        time_stop: $time_stop,
      }
    )
  }
`;

export const STATISTIC_MONTH_OPERATING_DiARY = gql`
  query staticMonthOperatingDiary(
    $devicesId: String
    $time_start: DateTime
    $time_stop: DateTime
  ) {
    staticMonthOperatingDiary(
      searchOptions: {
        devicesId: $devicesId,
        time_start: $time_start,
        time_stop: $time_stop,
      }
    )
  }
`;

export const STATISTIC_DAY_OPERATING_DiARY = gql`
  query staticDayOperatingDiary(
    $devicesId: String
    $time_start: DateTime
    $time_stop: DateTime
  ) {
    staticDayOperatingDiary(
      searchOptions: {
        devicesId: $devicesId,
        time_start: $time_start,
        time_stop: $time_stop,
      }
    )
  }
`;