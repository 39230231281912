import { gql } from "@apollo/client";
export * from './users';
export * from './departmanents';
export * from './devices';
export * from './identify_code';
export * from './general';
export * from './period_maintenance';
export * from './emergency_maintenance';
export * from './system_logs';
export * from './statistic_maintain';
export const queryGetAllPermission = (user_id) => {
  let GET_ALL_PERMISSIONS = gql`
    query getAllPermissions {
        getAllPermissions(_id: "${user_id}")
    }`;

  return GET_ALL_PERMISSIONS;
}