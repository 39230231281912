import { gql } from "@apollo/client";
export const GET_ALL_ASSET = gql`
  query getAllAssetCode(
      $search: String,
      $active: Boolean,
      ) {
    getAllAssetCode(
      search: $search,
      active: $active,
      ){
        _id
        name
        code
        note
        deleted
        createdAt
        updatedAt
        deletedAt
        createdBy {
          _id
          fullname
          username
        }
    }
  }
`;

export const GET_ALL_WORK_LIST = gql`
  query getAllWorkListCode($search: String, $active: Boolean ) {
    getAllWorkListCode(search: $search, active: $active)
    {
      _id
      name
      code
      note
      deleted
      createdAt
      updatedAt
      deletedAt
      createdBy {
        _id
        fullname
        username
      }
    }
  }
`;

export const GET_ALL_GROUP_DEVICE_LIST = gql`
  query getAllDeviceGroupCode($search: String) {
    getAllDeviceGroupCode(search: $search)
    {
      _id
      name
      code
      note
      deleted
      createdAt
      updatedAt
      deletedAt
      createdBy {
        _id
        fullname
        username
      }
    }
  }
`;
