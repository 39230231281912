import Cookies from "js-cookie";
import moment from "moment";
import client from "../client";
import CryptoJS from "crypto-js";
import { REFRESH_TOKENS } from "../graphql/mutations/_indexMutations";
import {
  GET_USER,
  GET_MENU,
  GET_OUTSIDE,
  GET_PERMISSIONS,
  GET_PERMISSIONS_HISTORY
} from "../graphql/queries/_indexQueries";
import { indexConstant } from "../utility/constants/_indexConstants";
import DialogUtil from "../views/component/show_confirm_action";
import { isDateTime } from "../utility/validate_checking";
require("dotenv").config();

const RefreshTokens = async () => {
  let res = {
    accessToken: null,
    isError: false,
  };
  await client
    .mutate({
      mutation: REFRESH_TOKENS,
      variables: {
        accessToken: Cookies.get(indexConstant.ACCESS_TOKEN),
        refreshToken: CryptoJS.AES.decrypt(
          Cookies.get(indexConstant.REFRESH_TOKEN)?.toString(),
          process.env.REACT_APP_ENCRYPT_KEY
        )?.toString(CryptoJS.enc.Utf8),
      },
    })
    .then((tokenData) => {
      Cookies.set(
        indexConstant.ACCESS_TOKEN,
        tokenData.data?.refreshTokens?.tokens?.accessToken
      );
      let refreshToken = CryptoJS.AES.encrypt(
        tokenData.data?.refreshTokens?.tokens?.refreshToken,
        process.env.REACT_APP_ENCRYPT_KEY
      )?.toString();
      Cookies.set(indexConstant.REFRESH_TOKEN, refreshToken);
      res.accessToken = tokenData.data?.refreshTokens?.tokens?.accessToken;
    })
    .catch((error) => {
      Cookies.remove(indexConstant.ACCESS_TOKEN);
      Cookies.remove(indexConstant.REFRESH_TOKEN);
      Cookies.remove(indexConstant.USER_DATA);
      res.isError = true;
    });
  return res;
};

const GetPermission = async () => {
  let res = {
    permission: [],
    isError: false,
  };
  await client
    .query({
      fetchPolicy: "no-cache",
      query: GET_PERMISSIONS,
    })
    .then(({ data }) => {
      res.permission = data?.getPermissions?.permission?.permissions;
    })
    .catch((error) => {
      console.log("GetPermission error :", error);
      // Cookies.remove(indexConstant.ACCESS_TOKEN);
      // Cookies.remove(indexConstant.REFRESH_TOKEN);
      // Cookies.remove(indexConstant.USER_DATA);
      res.isError = true;
    });
  return res;
};

const GetUser = async () => {
  let res = {
    dataError: false,
    SeverError: false,
    dataUser: {},
  };
  await client
    .query({
      query: GET_USER,
    })
    .then(({ data }) => {
      if (data?.getUser) {
        const user = {
          id: data.getUser?.id,
          fullName: data.getUser?.fullname,
          userName: data.getUser?.username,
          phoneNumber: data.getUser?.phoneNumber,
          address: data.getUser?.address,
          birthDay: data.getUser?.birthDay,
          avatar: data.getUser?.avatar,
          email: data.getUser?.emails[0].address,
          factoryId: data.getUser.factoryId,
          role: data.getUser.role,
          gender: data.getUser.gender,
          // namespace: data.getUser.namespace,
        };
        let userData = CryptoJS.AES.encrypt(
          JSON.stringify(user),
          process.env.REACT_APP_ENCRYPT_KEY
        ).toString();
        Cookies.set(indexConstant.USER_DATA, userData);
        res.dataUser = user;
      } else {
        res.dataError = true;
      }
    })
    .catch((error) => {
      console.log("GetUser error :", error);
      Cookies.remove(indexConstant.ACCESS_TOKEN);
      Cookies.remove(indexConstant.REFRESH_TOKEN);
      Cookies.remove(indexConstant.USER_DATA);
      res.SeverError = true;
    });
  return res;
};

const GetMenu = async () => {
  let data = await client.query({
    fetchPolicy: "no-cache",
    query: GET_MENU,
  });
  let dataMenu = data.data.getMenu.inSidebar.items;
  let dataOutSide = data.data.getMenu.outSidebar;
  if (dataMenu) {
    let res = {};
    res.routers = [];
    res.navigation = [];
    res.routerLink = [];

    res.routerLink.push({
      function: "THE_LAYOUT",
      to: data.data.getMenu.inSidebar.prefix,
    });

    dataOutSide.items.forEach((element) => {
      res.routerLink.push(element);
    });

    dataMenu.forEach((element) => {
      if (element.items.length > 0) {
        var chilItem = [];
        element.items.forEach((itemChild) => {
          switch (itemChild.function) {
            case "INFO_BASIC":
              res.routerLink.push(itemChild);
              break;
            case "INFO_USER":
              res.routerLink.push(itemChild);
              break;
            default:
              break;
          }
          if (itemChild.isOnSidebar === true) {
            chilItem.push({
              _tag: "CSidebarNavItem",
              name: itemChild.name,
              to: itemChild.to,
              function: itemChild.function,
              menuAlias: itemChild.menuAlias,
            });
          }
        });
        res.navigation.push({
          _tag: "CSidebarNavDropdown",
          name: element.title,
          _children: chilItem,
          icon: element.icon,
          menuAlias: element.menuAlias,
        });
      }
    });
    dataMenu.map((item) => {
      if ("items" in item && item.items.length > 0) {
        item.items.map((itemChild) => {
          res.routers.push({
            function: itemChild.function,
            name: itemChild.name,
            path: itemChild.to,
            component: itemChild.component,
            menuAlias: itemChild.menuAlias,
            icon: itemChild.icon,
          });
        });
      }
    });
    return res;
  } else {
    return null;
  }
};

const getOutSide = async () => {
  let data = await client.query({
    query: GET_OUTSIDE,
  });

  let dataOutSide = data.data.getOutSide.outSidebar;
  if (dataOutSide) {
    let res = [];
    res = dataOutSide.items;
    return res;
  } else {
    return null;
  }
};
const clearObj = (o) => {
  for (var k in o) {
    if (!o[k]) {
      delete o[k];
    }
    if (Array.isArray(o[k])) {
      o[k] = o[k].filter((e) => e);
      clearObj(o[k]);
    } else if (typeof o[k] === "object") {
      clearObj(o[k]);
      if (Object.keys(o[k]).length === 0 && !isDateTime(o[k])) {
        delete o[k];
      }
    }
  }
  return o;
};

export { RefreshTokens, GetUser, GetMenu, getOutSide, GetPermission, clearObj };
