import { Observable } from 'apollo-link';
import DialogUtil from '../views/component/show_confirm_action';
import {indexGeneral} from '../language/vn/general/_indexGeneral';
export default promise =>
  new Observable((subscriber) => {
    promise.then(
      (res) => {
        if (!res.isError) {
          if (subscriber.closed) return;
          subscriber.next(res);
          subscriber.complete();
          return res.accessToken
        } else {
          // err => subscriber.error(err)
          DialogUtil.showAlert(indexGeneral.ALERT, indexGeneral.ACCESS_EXPIRE, () => window.location.assign('/login'));
        }
      },
    );
  });