import { gql } from "@apollo/client";
export const LOGIN_MUTATION = gql`
    mutation authenticate (
      $password: String!
      $email: String!
      $username: String!
  ){
        authenticate (
        serviceName: "password"
        params: 
        {
            password: $password
            user: {
              email: $email
              username: $username
            }
        }
      )
    {
      tokens {refreshToken, accessToken}
      user {
        id
        emails {
          address
          verified
        }
        username
        phoneNumber
        fullname
        address {
          detail
          ward {
            code
            name
          }
          district {
            code
            name
          }
          city {
            code
            name
          }
        }
        birthDay
        avatar
        deletedAt
      } 
    }
  }
`;

export const UPDATE_USER = gql`
  mutation(
    $input: CreateUserInput,
    $_id: String!,
    ){
    updateUser(input: $input, _id: $_id){
      id
      emails {
        address
        verified
      }
      username
      phoneNumber
      fullname
      gender
      address {
        detail
        ward {
          code
          name
        }
        district {
          code
          name
        }
        city {
          code
          name
        }
      }
      birthDay
      avatar
      deletedAt
      role
      factoryId
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword (
    $oldPassword: String!,
    $newPassword: String!
  ){
    changePassword(
      oldPassword: $oldPassword,
      newPassword: $newPassword
    )
  }
`;

export const CREATE_USER = gql`
  mutation($user: CreateUserInput!){
    createUser(user: $user){
      userId
      loginResult {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
      user {
        id
        emails {
          address
          verified
        }
        username
        phoneNumber
        fullname
        address {
          detail
          ward {
            code
            name
          }
          district {
            code
            name
          }
          city {
            code
            name
          }
        }
        birthDay
        avatar
        deletedAt
        role
        factoryId
      }
    }
    }
  }
`;
export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($_id: String!){
    deleteAccount(
      _id: $_id,
    )
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPasswordAccount($_id: String!, $newPassword: String!){
    resetPasswordAccount(
      _id: $_id,
      newPassword: $newPassword,
    )
  }
`;

export const REFRESH_TOKENS = gql`
  mutation refreshTokens (
    $accessToken: String!,
    $refreshToken: String!
  )
  {
    refreshTokens(
      accessToken: $accessToken,
      refreshToken: $refreshToken
    ){
      tokens{
        refreshToken,
        accessToken
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
    mutation {
        logout
    }
`;

export const DELETE_USER_TRANSFER = gql`
  mutation deleteUserTransfer($_id: String!){
    deleteUserTransfer(_id: $_id)
  }
`;

export const CONFIRM_USER_TRANSFER = gql`
  mutation confirmUserTransfer($_id: String!){
    confirmUserTransfer(_id: $_id)
  }
`

export const ADD_USER_TRANSFER = gql`
mutation(
  $input: AddUserTransferInput!,
  $files: [Upload],
  ){
  addUserTransfer(
    input: $input,
    files : $files,
    ) {
  _id
  confirmId
  confirmDate
  certificateId
  certificateDate
  reason
  content
  confirmUserId
  isConfirm
  transfer_histories {
    _id
    factoryCurrentId{
      _id,
      name
    },
    stationCurrentId{
      _id,
      name
    },
    factoryToId{
      _id,
      name
    },
    stationToId{
      _id,
      name
    },
    roleToAlias
    roleCurrentAlias
    note
    userTransferId {
      _id
      fullname
      username
    }
  }
  createdAt
  updatedAt
  createdBy {
    _id
    fullname
    username
  }
  updatedBy {
    _id
    fullname
    username
  }
  deleted
  deletedAt
  }
}
`;

export const UPDATE_USER_TRANSFER = gql`
mutation(
  $_id: String!,
  $input: AddUserTransferInput!,
  $files: [Upload],
  ) {
  updateUserTransfer(
  _id: $_id,
  input: $input,
  files : $files,
  ) {
    _id
    confirmId
    confirmDate
    certificateId
    certificateDate
    reason
    content
    confirmUserId
    isConfirm
    transfer_histories {
      _id
      factoryCurrentId{
        _id,
        name
      },
      stationCurrentId{
        _id,
        name
      },
      factoryToId{
        _id,
        name
      },
      stationToId{
        _id,
        name
      },
      roleToAlias
      roleCurrentAlias
      note
      userTransferId {
        _id
        fullname
        username
      }
    }
    createdAt
    updatedAt
    createdBy {
      _id
      fullname
      username
    }
    updatedBy {
      _id
      fullname
      username
    }
    deleted
    deletedAt
    }
}
`;

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation(
    $email: String!
    ) {
    sendResetPasswordEmail(
      email: $email
    )
  }
`;

export const RESET_PASSWORD_EMAIL = gql`
  mutation(
    $token: String!,
    $newPassword: String!
    ) {
    resetPassword(
      token: $token,
      newPassword: $newPassword
    ){
      sessionId
    }
  }
`;

export const CHANGE_ACTIVE = gql`
    mutation($_id: String!) {
        changeActiveAccount(_id: $_id)
    }
`;

export const WRITE_DIARY = gql`
    mutation ($_id: String!) {
      writeDiaryCreateUser(_id: $_id)
    }
`