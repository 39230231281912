import { gql } from "@apollo/client";
export const ADD_FACTORY = gql`
  mutation($input:AddFactoryInput){
    addFactory(input: $input){
      _id
      name
      address {
        detail
        ward {
          code
          name
        }
        district {
          code
          name
        }
        city {
          code
          name
        }
      }
      phone
      fax
      workingArea
      description
      coordinates {
        latitude
        longitude
      }
      type
      alias
      createdBy
      updatedBy
      deletedBy
    }
  }
`;
export const EDIT_FACTORY = gql`
  mutation ($input: EditFactoryInput){
    editFactory(input: $input) {
      _id
      name
      address {
        detail
        ward {
          code
          name
        }
        district {
          code
          name
        }
        city {
          code
          name
        }
      }
      phone
      fax
      workingArea
      description
      coordinates {
        latitude
        longitude
      }
      type
      alias
      createdBy
      updatedBy
      deletedBy
    }
  }
`;
export const DELETE_FACTORY = gql`
  mutation deleteFactory($_id: String!){
    deleteFactory(_id: $_id)
  }
`;
export const ADD_STATION = gql`
  mutation($input:AddStationInput){
    addStation(input: $input) {
      _id
      name
      address {
        detail
        ward {
          code
          name
        }
        district {
          code
          name
        }
        city {
          code
          name
        }
      }
      alias
      phone
      fax
      workingArea
      description
      coordinates {
        latitude
        longitude
      }
      factory
      createdBy
      updatedBy
      deletedBy
    }
  }
`;

export const DELETE_STATION = gql`
  mutation deleteStation($_id: String!){
    deleteStation(_id: $_id)}
  `;
export const UPDATE_STATION = gql`
  mutation ($input: EditStationInput){
    updateStation(input: $input) {
      _id
      name
      address {
        detail
        ward {
          code
          name
        }
        district {
          code
          name
        }
        city {
          code
          name
        }
      }
      phone
      fax
      workingArea
      description
      coordinates {
        latitude
        longitude
      }
      factory
      createdBy
      updatedBy
      deletedBy
    }
    }
  `;