import { gql } from '@apollo/client';
export const GET_PAGINATE_CYCLE_MAINTAIN = gql`
  query getPaginateCycleMaintain(
    $search: String
    $status: String
    $type: Int
    $page: Int
    $limit: Int
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getPaginateCycleMaintain(
      searchCycleMaintain: {
        searchOptions: {
          search: $search
          status: $status
          type: $type
          startDate: $startDate
          endDate: $endDate
        }
        page: $page
        limit: $limit
      }
    ) {
      hashMore
      hasNextPage
      hasPrevPage
      limit
      page
      pagingCounter
      totalDocs
      totalPages
      docs {
        _id
        name
        status
        startDate
        endDate
        note
        filesId {
          _id
          status
          tags
          filename
          mimeType
          size
          path
          user {
            _id
            fullname
          }
          createdAt
        }
        worksId {
          _id
        }
        createdBy {
          _id
          fullname
          username
        }
        updatedBy {
          _id
          fullname
          username
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_CYCLE_MAINTAIN = gql`
  query {
    getAllCycleMaintain(searchOptions: { type: 1 }) {
      _id
      name
      status
      startDate
      endDate
      note
      createdAt
      updatedAt
    }
  }
`;

export const GET_CYCLE_WORK = gql`
  query getPaginateCycleWork(
    $_id: String!
    $search: String
    $status: [EnumAllStatusWork]
    $factoriesId: [String]
    $stationsId: [String]
    $startDate: DateTime
    $endDate: DateTime
    $workCodesId: [String]
    $deviceGroupId: [String]
    $type: Int
    $limit: Int
    $page: Int
    $isAccept: Int
  ) {
    getPaginateCycleWork(
      _id: $_id
      searchWork: {
        searchOptions: {
          search: $search
          status: $status
          factoriesId: $factoriesId
          type: $type
          workCodesId: $workCodesId
          startDate: $startDate
          endDate: $endDate
          stationsId: $stationsId
          deviceGroupId: $deviceGroupId
          isAccept: $isAccept
        }
        page: $page
        limit: $limit
      }
    ) {
      hashMore
      hasNextPage
      hasPrevPage
      limit
      page
      pagingCounter
      totalDocs
      totalPages
      docs {
        _id
        name
        filesId {
          _id
          status
          tags
          filename
          mimeType
          size
          path
          user {
            username
            _id
            gender
            phoneNumber
            fullname
            birthDay
            avatar
            deletedAt
            role
            namespace
            factoryId
          }
          createdAt
          updatedAt
        }
        maintainCycle {
          ammount
          type
        }
        station
        factory
        worker
        status
        cost {
          supply
          worker
          sum
        }
        workDetailsId {
          _id
          name
          original
          description
          reason
          positionUsing
          workingBy
          workId
          deviceSku
          startTime
          endTime
          status
          cost {
            supply
            worker
            sum
          }
          filesId {
            _id
            status
            tags
            filename
            mimeType
            size
            path
            user {
              username
              _id
              fullname
              avatar
              factoryId
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          createdBy {
            _id
            fullname
          }
          updatedBy {
            _id
            fullname
          }
        }
        deleted
        workCodeId {
          _id
          name
          code
        }
        deviceGroupId {
          _id
          name
          code
        }
        content
        isAccept
        startDate
        endDate
        ammountUnit
        typeUnit
        note
        cycleMaintainId {
          _id
          name
        }
        createdAt
        updatedAt
        createdBy {
          _id
          fullname
        }
        updatedBy {
          _id
          fullname
        }
      }
    }
  }
`;

export const FIND_WORK = gql`
  query findWorkById($_id: String!) {
    findWorkById(_id: $_id) {
      _id
      workDetailsId {
        _id
        name
        original
        description
        reason
        positionUsing
        workingBy
        deviceSku
        deviceId
        startTime
        endTime
        status
        replaceDevice
        infoAfter
        worker
        cost {
          supply
          worker
          sum
        }
        note
        filesId {
          _id
          status
          tags
          filename
          mimeType
          size
          path
          user {
            id
            username
            _id
            fullname
            avatar
            factoryId
          }
          createdAt
          updatedAt
        }
        createdAt
        createdBy {
          _id
          fullname
        }
        updatedAt
        updatedBy {
          _id
          fullname
        }
      }
    }
  }
`;
