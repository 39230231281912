import { gql } from "@apollo/client";
export const ADD_ASSET_CODE = gql`
  mutation 
  addAssetCode(
    $name : String!,
    $code :String!,
    $note : String,
  ) {
    addAssetCode(
      input :{
        name: $name,
        code: $code,
        note: $note,
      }
    ) {
      _id
      name
      code
      note
    }
  }
`;

export const ADD_GROUP_DEVICES_CODE = gql`
  mutation
  addDeviceGroupCode(
      $name: String!,
      $code: String!,
      $note: String,
    ) {
      addDeviceGroupCode( input : {
        name: $name,
        code: $code,
        note: $note,
      }) {
        _id
        name
        code
        note
      }
    }
`;

export const UPDATE_ASSET = gql`
  mutation
  updateAssetCode(
    $name : String!,
    $code :String!,
    $note : String,
    $_id: String!,
  ){
    updateAssetCode(
      input: {
        name: $name,
        code: $code,
        note: $note,
      },
      _id: $_id,
    ) {
      _id
      name
      code
      note
    }
  }
`;

export const DELETE_ASSET = gql`
  mutation
    deleteAssetCode($_id: String){
      deleteAssetCode(_id: $_id)
    }
`;

export const DELETE_GROUP_DEVICE = gql`
  mutation
  deleteDeviceGroupCode(
      $_id: String!
    ) {
      deleteDeviceGroupCode( _id: $_id)
    }
`;

export const UPDATE_GROUP_DEVICES = gql`
  mutation
  updateDeviceGroupCode(
      $_id: String!
      $name: String!,
      $code: String!,
      $note: String,
    ) {
      updateDeviceGroupCode( input : {
        name: $name,
        code: $code,
        note: $note,
      }, _id: $_id)
      {
        _id,
        name,
        code,
        note,
        deleted
      }
    }
`;

export const ADD_WORK_LIST_CODE = gql`
  mutation
  addWorkListCode(
      $name: String!,
      $code: String!,
      $note: String,
    ) {
      addWorkListCode( input : {
        name: $name,
        code: $code,
        note: $note,
      }){
        _id
        name
        code
        note
      }
    }
`;

export const DELETE_WORK_LIST = gql`
  mutation
  deleteWorkListCode(
      $_id: String!
    ) {
      deleteWorkListCode( _id: $_id)
    }
`;

export const UPDATE_WORK_LIST = gql`
  mutation
  updateWorkListCode(
      $_id: String!
      $name: String!,
      $code: String!,
      $note: String,
    ) {
      updateWorkListCode( input : {
        name: $name,
        code: $code,
        note: $note,
      }, _id: $_id)
      {
        _id
        name
        code
        note
        deleted
      }
    }
`;

