import { gql } from "@apollo/client";

export const GET_FACTORY = gql`
  query($type: Int) {
      getFactory(type: $type) {
          _id
          name
          address {
            detail
            ward {
              code
              name
            }
            district {
              code
              name
            }
            city {
              code
              name
            }
          }
          phone
          alias
          fax
          workingArea
          description
          stations {
            _id
            name
            alias
            address {
              detail
              ward {
                code
                name
              }
              district {
                code
                name
              }
              city {
                code
                name
              }
            }
            phone
            fax
            workingArea
            description
            coordinates {
              latitude
              longitude
            }
            factory
            deleted
            createdBy
            updatedBy
            deletedBy
          }
          coordinates {
            latitude
            longitude
          }
          type
          alias
      }

  }
`;

export const GET_ALL_FACTORY = gql`
  query($type: Int) {
    getAllFactory(type: $type) {
          _id
          name
          address {
            detail
            ward {
              code
              name
            }
            district {
              code
              name
            }
            city {
              code
              name
            }
          }
          phone
          fax
          workingArea
          description
          stations {
            _id
            name
            address {
              detail
              ward {
                code
                name
              }
              district {
                code
                name
              }
              city {
                code
                name
              }
            }
            phone
            fax
            workingArea
            description
            coordinates {
              latitude
              longitude
            }
            factory
            deleted
            createdBy
            updatedBy
            deletedBy
          }
          coordinates {
            latitude
            longitude
          }
          type
          alias
      }

  }
`;

export const GET_PROVINCES = gql`
  query{
    getProvinces{
      name
      code
    }
  }
`;

export const GET_DISTRICT = gql`
  query getDistrictByCodeProvince($codePro: Int!) {
    getDistrictByCodeProvince(codePro: $codePro) {
      name
      code
      codename
      division_type
      phone_code
      districts {
        name
        code
        codename
        division_type
        short_codename
        wards {
          _id
          name
          code
          codename
          division_type
          short_codename
        }
      } 
    }
  }
`;

export const GET_WARD = gql`
  query getWardByCodeDistrict($codeDis: Int!) {
    getWardByCodeDistrict(codeDis: $codeDis) {
      districts {
        wards {
          name
          code
        }
      } 
    }
  }
`;

export const GET_ALL_SELECT = gql`
  query getAllSelect
    (
      $codePro: Int!,
      $codeDis: Int!,
    ) {
      getAllSelect
    (
      codePro: $codePro,
      codeDis: $codeDis,
    ) {
      provinces {
        _id
        name
        code
        codename
        division_type
        phone_code
      }
      districts {
        _id
        name
        code
        codename
        division_type
        short_codename
      }
      wards {
        _id
        name
        code
        codename
        division_type
        short_codename
      }
    }
  }
`;

export const GET_DEPARTMENT = gql`
  query {
    getFactory {
        _id
        name
    }
  }
`;

export const FIND_FACTORY = gql`
  query findFactory($_id: String!) {
    findFactory(_id: $_id) {
      _id
      name
      address{
        detail
        ward{
          code
          name
        }
        district{
          code
          name
        }
        city{
          code
          name
        }
      }
      phone
      fax
      workingArea
      description
      coordinates{
          latitude
          longitude
        }
      type
      alias
      stations
    {
      _id
      name
      address{
        detail
        ward{
          code
          name
        }
        district{
          code
          name
        }
        city{
          code
          name
        }
      }
      phone
      fax
      workingArea
      description
      coordinates{
        latitude
        longitude
      }
      factory
    }
    }
  }
`;

export const GET_All_STATION = gql`
  query{
    getAllStation{
      _id
      name
    }
  }
`;