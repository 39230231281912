import { gql } from "@apollo/client";
export const GET_DIARY = gql`
    query getPaginateDiary(
        $search: String,
        $limit: Int,
        $page: Int,
        $startTime: DateTime,
        $endTime: DateTime,
        $userId:[String],
        $ctx: [String],
    ) {
        getPaginateDiary(
            searchDiary: {
                searchOptions: {
                    search: $search,
                    startTime: $startTime,
                    endTime: $endTime,
                    userId: $userId,
                    ctx : $ctx,
                }
                limit: $limit,
                page: $page,
            }
        ){
            hashMore
            hasNextPage
            hasPrevPage
            limit
            page
            pagingCounter
            totalDocs
            totalPages
            docs {
              _id
              u_id {
                _id
                fullname
                username
              }
              t
              ctx
              co_id
              co_name
              msg
              ag
              ip
            }
          }
    }
`