module.exports = Object.freeze({
    ALERT: "通知",
    EXITED_USERNAME: "ログイン名がすでに存在しています",
    EXITED_EMAIL: "メールアドレスがすでに存在しています",
    ACCESS_EXPIRE: "セッションが終了しました。再度ログインしてください。",
    PASSWORD_ERROR: "パスワードが正しくありません",
    EMAIL_ERROR: "メールアドレスがすでに存在するか、形式が正しくありません",
    USERNAME_ERROR: "ログイン名がすでに存在するか、形式が正しくありません",
    FULL_NAME_ERROR: "ユーザー名が正しくありません",
    SUCCESS: "成功",
    SUCCESS_EMAIL: "メールを送信して構成を確認しました",
    SUCCESS_EDITED: "編集が成功しました",
    SUCCESS_RESTORE: "リカバリが成功しました",
    SUCCESS_ADDED_DATA: 'データの追加が成功しました',
    SUCCESS_SAVED: "保存が成功しました",
    SUCCESS_DELETED_WORK_HISTORY: "作業履歴の削除が成功しました",
    SUCCESS_DELETED_DEPARTMENT: 'ユニットの削除が成功しました',
    SUCCESS_DELETED_FAIL: 'ユニットの削除が失敗しました。もう一度確認してください',
    INPUT_INFORMATION_ERROR: "入力情報が正しくありません",
    TYPE_IMAGE_ERROR: "画像ファイルの形式が正しくありません",
    CONNECTION_ERROR: "接続エラーが発生しました。再試行してください",
    CONTINUE: "操作を続行しますか?",
    CONTINUE_LOGOUT: "ログアウトしますか？",
    NO_PERMISSION_VIEW_PAGE: "このページにアクセスする権限がありません",
    YES: "はい",
    NO: "いいえ",
    NO_RESULTS: '一致する結果がありません',
    NO_DATA: 'データがありません',
    ERROR_LOGIN: 'ログインエラー。もう一度確認してください',
    LOCK_ACCOUNT: 'アカウントはロックされています。サポートに連絡してください'
});
