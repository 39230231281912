import { gql } from "@apollo/client";

export const GET_SETTING_EMAIL = gql`
  query{
      getSettingEmail{
          service
          host
          serverEmail
          security
          port
          accuracy
          account
          password
          sendFrom
          proxy
          mailCC
          deleted
          createdAt
          updatedAt
          deletedAt
          createdBy
          updatedBy
          deletedBy
      }
  }
`;

export const GET_OUTSIDE = gql`
  query{
    getOutSide
  }
`;

export const GET_MENU = gql`
  query{
    getMenu
  }
`;

export const GET_ROLE = gql`
  query{
    getAllRole{
      name
      alias
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query{
    getPermissions
  }`
  ;

export const GET_NOTIFICATION = gql`
  query(
        $page: Int,
        $limit: Int,
    ){
    getNotification(page: $page, limit: $limit) {
        notifications {
            hashMore
            hasNextPage
            hasPrevPage
            limit
            page
            pagingCounter
            totalDocs
            totalPages
            docs {
              is_read
              deleted
              f_id
              relate
              content {
                from
                subject
                content
                title
                icon
                startTime
                endTime
                nextTime
              }
              menu_alias
              createdAt
              updatedAt
            }
          }
          unread
        }
  }
`;

export const STATISTIC_GENERAL = gql`
  query statisticGeneral(
    $startDate: DateTime,
    $endDate: DateTime,
    $type: Int,
  ){
    statisticGeneral(
        searchOptions: {
            startDate: $startDate,
            endDate: $endDate,
            type: $type,
        }
        
    )
  }
`;

export const STATISTIC_WORK = gql`
  query statisticWork(
    $startDate: DateTime,
    $endDate: DateTime,
    $type: Int,
  ){
    statisticWork(
        searchOptions: {
            startDate: $startDate,
            endDate: $endDate,
            type: $type,
        }
    )
  }
`