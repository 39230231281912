import { gql } from '@apollo/client';

export const GET_STATIC_MAINTAIN = gql`
  query getPaginateStatisticMaintain(
    $search: String
    $status: [EnumAllStatusWork]
    $factoriesId: [String]
    $stationsId: [String]
    $createdBy: [String]
    $startDate: DateTime
    $endDate: DateTime
    $isAccept: Int
    $limit: Int
    $page: Int
    $type: Int
    $isCycleMaintain: Int
    $isPublic: Boolean
  ) {
    getPaginateStatisticMaintain(
      searchOptions: {
        search: $search
        status: $status
        factoriesId: $factoriesId
        stationsId: $stationsId
        createdBy: $createdBy
        startDate: $startDate
        endDate: $endDate
        type: $type
        isAccept: $isAccept
        isPublic: $isPublic
        isCycleMaintain: $isCycleMaintain
      }
      page: $page
      limit: $limit
    ) {
      hashMore
      hasNextPage
      hasPrevPage
      limit
      page
      pagingCounter
      totalDocs
      totalPages
      docs {
        _id
        filesId {
          _id
          status
          tags
          filename
          mimeType
          size
          user {
            emails {
              address
            }
            username
            _id
            fullname
            gender
            phoneNumber
            avatar
            namespace
            address {
              ward {
                code
              }
            }
          }
          createdAt
        }
        maintainCycle {
          ammount
          type
        }
        station
        factory
        status
        isCycleMaitain
        workDetailsId {
          _id
          status
          deviceSku
          name
          cost {
            supply
            worker
            sum
          }
        }
        deleted
        workCodeId {
          _id
          name
          code
        }
        deviceGroupId {
          _id
          name
          code
        }
        name
        content
        startDate
        endDate
        reportId
        reportDate
        ammountUnit
        typeUnit
        note
        cost {
          supply
          worker
          sum
        }
        isAccept
        cycleMaintainId {
          _id
          name
        }
        createdBy {
          _id
          fullname
        }
        updatedBy {
          _id
          fullname
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const STATIC_MAINTAIN = gql`
  query statisticMaintain(
    $search: String
    $status: [EnumAllStatusWork]
    $factoriesId: [String]
    $stationsId: [String]
    $createdBy: [String]
    $startDate: DateTime
    $endDate: DateTime
    $isAccept: Int
    $isPublic: Boolean
    $isCycleMaintain: Int
    $type: Int
  ) {
    statisticMaintain(
      searchOptions: {
        search: $search
        status: $status
        factoriesId: $factoriesId
        stationsId: $stationsId
        createdBy: $createdBy
        startDate: $startDate
        endDate: $endDate
        type: $type
        isAccept: $isAccept
        isPublic: $isPublic
        isCycleMaintain: $isCycleMaintain
      }
    )
  }
`;
export const STATISTIC_SCHEDULER = gql`
  query statisticScheduler(
    $searchOptions: SearchOptionScheduler!
    $page: Int
    $limit: Int
  ) {
    statisticScheduler(
      searchOptions: $searchOptions
      page: $page
      limit: $limit
    )
  }
`;
export const STATISTIC_DEVICE = gql`
  query statisticDevice($searchOptions: SearchDashboardOptions!) {
    statisticDevice(searchOptions: $searchOptions)
  }
`;
