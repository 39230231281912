import { gql } from "@apollo/client";
export const EDIT_STORE_EMAIL_SETTING = gql`
  mutation editAndStoreEmailSetting (
    $service :String!,
    $host :String!,
    $serverEmail :EnumEmailServer!,
    $security :EnumEmailSecurity!,
    $port :Int!,
    $accuracy :Boolean!,
    $account :String!,
    $password :String!,
    $sendFrom :String!,
  ){
    editAndStoreEmailSetting (
      input :{
        service : $service,
        host : $host,
        serverEmail : $serverEmail,
        security : $security,
        port : $port,
        accuracy : $accuracy,
        account : $account,
        password : $password,
        sendFrom : $sendFrom,
      }
    ){
      service 
      host
      serverEmail 
      security
      port
      accuracy
      account
      password
      sendFrom
    }
  }
`;

export const DELETE_EMAIL_SETTING = gql`
  mutation{
    deleteEmailSetting{
      service
      host
      serverEmail
      security
      port
      accuracy
      account
      password
      sendFrom
      mailCC
      deleted
      createdAt
      updatedAt
      deletedAt
      createdBy
      updatedBy
      deletedBy
    }
  }
`;

export const RESTORE_EMAIL_SETTING = gql`
  mutation{
    restoreEmailSetting{
      service
      host
      serverEmail
      security
      port
      accuracy
      account
      password
      sendFrom
      mailCC
      deleted
      createdAt
      updatedAt
      deletedAt
      createdBy
      updatedBy
      deletedBy
    }
  }
`;

export const GET_LINK = gql`
  mutation getLinkFile($_id: String!){
    getLinkFile(_id: $_id)
    
  }
`;

export const STORE_PERMISSIONS = gql`
  mutation
    storePermissions(
      $user_id: String!,
      $post: Object,
    ) {
      storePermissions(
        user_id: $user_id,
        post: $post)
    }
`;

export const STORE_PERMISSION_REGION = gql`
  mutation
    storePermissionRegion(
      $user_id: String!,
      $inputs: [StorePRegionInput]!,
    ) {
      storePermissionRegion(
        user_id: $user_id,
        inputs: $inputs
    )
  }
`;

export const SEND_EMAIL_TEST = gql`
  mutation sendEmailTest (
    $service :String!,
    $host :String!,
    $serverEmail :EnumEmailServer!,
    $security :EnumEmailSecurity!,
    $port :Int!,
    $accuracy :Boolean!,
    $account :String!,
    $password :String!,
    $sendFrom :String!,
    $mailCC :String,
    $sendTo :String!
    $subject :String,
    $content :String
  ){
    sendEmailTest (
      input :{
        service : $service,
        host : $host,
        serverEmail : $serverEmail,
        security : $security,
        port : $port,
        accuracy : $accuracy,
        account : $account,
        password : $password,
        sendFrom : $sendFrom,
        mailCC : $mailCC,
        sendTo : $sendTo,
        subject : $subject,
        content : $content
      }
    )
  }
`;

export const REFRESH_PERMISSIONS = gql`
mutation refreshPermissions (
  $user_id :String!,
	){
    refreshPermissions(user_id: $user_id)
}
`;

export const READ_NOTIFICATION = gql`
mutation readNotification($_id:[String!]!){
    readNotification(_id: $_id)
}
`;

export const GET_LINK_DIARY = gql`
mutation getLinkExportDiary(
    $search: String, 
    $startTime: DateTime,
    $endTime: DateTime,
    $userId: [String],
    $ctx: [String],
    ){
    getLinkExportDiary(
        searchOptions: {
            search: $search,
            startTime: $startTime,
            endTime: $endTime,
            userId: $userId,
            ctx: $ctx,
        }
    )
}
`;

export const DELETE_FILE = gql`
  mutation deleteFile($_id: String!){
    deleteFile(_id: $_id)
  }
`;

export const GET_LINK_STATIC_SCHEDULER = gql`
  mutation getLinkExportStatisticScheduler(
    $period: Int,
  ){
    getLinkExportStatisticScheduler(
      searchOptions: {
        period: $period,
      }
    )
  }
`;